import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import PlayOutline from '@/assets/icons/video-play.svg?inline';

@Component({
  components: {
    PlayOutline,
  },
})
export default class PlayPlaceholderComponent extends Vue {
  openModal() {
    this.$emit('openModalEvent');
  }
}
