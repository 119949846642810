import { Component, Vue, Watch } from 'vue-property-decorator';
import { videoPlayer } from 'vue-video-player';
import VideoItem from '@/common/interfaces/VideoItem';

import { SegmentElement } from '@/plugins/utm/SegmentElement';

const VimeoVideoPlayerProps = Vue.extend({
  props: {
    video: Object as () => VideoItem,
    options: Object,
    fluid: Boolean,
    isActiveSlide: Boolean,
    autoplay: Boolean,
    externalStop: Boolean,
    noAnalytics: Boolean,
    controls: {
      type: Array as () => string[],
      default: () => ['nodownload'],
    },
  },
});

@Component({
  components: {
    videoPlayer,
  },
})
export default class VimeoVideoPlayer extends VimeoVideoPlayerProps {
  playing = false
  playingInterval: number;
  full_screen = false
  options = {
    byline: false,
    title: false,
    vimeo_logo: false,
    autoplay: this.$props.autoplay
  }
  get videoId() {
    if (this.video.Url.includes('external/')) {
      return this.video.Url.split('external/')[1].split('.hd')[0]
    }
    if (this.video.Url.includes('progressive_redirect')) {
      return this.video.Url.split('playback/')[1].split('/rendition')[0]
    }
  }
  onFullScreenChange(event) {
    this.full_screen = event.fullscreen
  }
  @Watch('playing')
  playingChanged() {
    if (this.playing) {
      this.setPlayingInterval()
    }
    if (!this.playing && this.playingInterval) {
      clearInterval(this.playingInterval)
      this.playingInterval = null
    }
  }
  setPlayingInterval() {
    if (!this.playingInterval) {
      this.playingInterval = setInterval(this.onPlaying, 10000)
    }
  }
  async getSegmentProps(event, data) {
    const sound = await data.getVolume()
    return {
      full_screen: this.full_screen,
      position: Math.floor(event.seconds),
      sound: Math.floor(sound * 100),
      total_length: Math.floor(event.duration),
      video_player: 'vimeo',
      asset_source: this.video.Url,
    }
  }
  async onPlay(event, data) {
    this.playing = true
    if (event) {
      const props = await this.getSegmentProps(event, data)
      SegmentElement.sendEvent('videoPlaybackStarted', props, true)
    }
  }
  async onPlaying() {
    // @ts-ignore
    const player = this.$refs.player.player
    const seconds = await player.getCurrentTime()
    const duration = await player.getDuration()
    const sound = await player.getVolume()
    SegmentElement.sendEvent('videoContentPlaying', {
      full_screen: this.full_screen,
      position: Math.ceil(seconds),
      sound: Math.floor(sound * 100),
      total_length: Math.floor(duration),
      video_player: 'vimeo',
      asset_source: this.video.Url,
    }, true)
  }
  async onEnded(event, data) {
    this.playing = false
    if (event) {
      const props = await this.getSegmentProps(event, data)
      SegmentElement.sendEvent('videoPlaybackCompleted', props, true)
    }
  }
  onPause() {
    this.playing = false
  }
}
