import { Component, Vue, Ref } from 'vue-property-decorator';

const CarouselPaginationProps = Vue.extend({
  props: {
    color: {
      type: String,
      default: 'black',
    },
    controlId: String,
    paginationClass: {
      type: String,
      default: '',
    },
    controlsWrapper: {
      type: String,
      default: '',
    },
    shouldAnimate: {
      type: Boolean,
      default: true,
    },
  },
});

@Component({})
export default class CarouselPagination extends CarouselPaginationProps {
  setColorFromProps() {
    return 'carousel-pagination--color-' + this.color;
  }
}
