import { Component, Vue } from 'vue-property-decorator';

const CarouselArrowProps = Vue.extend({
  props: {
    color: {
      type: String,
      default: 'black',
    },
    weight: {
      type: String,
      default: '1',
    },
    direction: {
      type: String,
      default: 'left',
    },
    width: {
      type: String,
      default: '100%',
    },
    maxWidth: {
      type: String,
      default: '100%',
    },
    navigationId: String,
    controlId: String,
  },
});

export default class CarouselArrow extends CarouselArrowProps {
  setClassesFromProps() {
    return [
      'carousel-arrow--' + this.direction,
      'carousel-arrow--weight-' + this.weight,
      'carousel-arrow--color-' + this.color,
    ];
  }

  setStyleFromProps() {
    return {
      width: this.width,
      maxWidth: this.maxWidth,
    };
  }
}
