import { Component } from 'vue-property-decorator';
import FocusTrapDirective from '@/directives/FocusTrapDirective';
import { SwiperOptions } from '@/utils/swiperUtils';

import { CarouselRichModuleViewModel } from './CarouselRichModule.types';
import {
  getComponentProps,
  getDefaultSwiperOptions,
  openModal,
  closeModal,
} from '@/utils/vueUtils';

import ResponsiveImageRatio from '@/components/ResponsiveImageRatio/ResponsiveImageRatio.vue';
import PlayPlaceholder from '@/components/PlayPlaceholder/PlayPlaceholder.vue';
import VimeoVideoPlayer from '@/components/VimeoVideoPlayer/VimeoVideoPlayer.vue';
import ArrowLong from '@/assets/icons/arrow-long.svg?inline';
import Cross from '@/assets/icons/cross.svg?inline';
import { ImageWidths } from '@/common/interfaces/SizeBreakpoints';

import { getResizeImage } from '@/utils/commonUtils';

import CarouselPagination from '@/components/CarouselPagination/CarouselPagination.vue';
import CarouselArrow from '@/components/CarouselArrow/CarouselArrow.vue';
import { mixins } from 'vue-class-component';
import SwiperMixin from '@/mixins/SwiperMixin';
import { SegmentElement } from '@/plugins/utm/SegmentElement';
import { stripHtml } from '@/utils/stringUtils';

@Component({
  components: {
    ArrowLong,
    Cross,
    VimeoVideoPlayer,
    ResponsiveImageRatio,
    CarouselPagination,
    CarouselArrow,
    PlayPlaceholder,
  },
  directives: {
    'trap-focus': FocusTrapDirective,
  },
  props: getComponentProps<CarouselRichModuleViewModel>({
    Title: '',
    Slides: [
      {
        Title: 'Up close to Alaska whales',
        Text:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dolor morbi non arcu risus quis varius quam. Id consectetur purus ut faucibus pulvinar',
        Image: { Url: '@/assets/images/hike.jpg', Alt: 'Hikers' },
        Video: {
          Url:
            'https://player.vimeo.com/external/378567776.hd.mp4?s=a1da3193ace2f94a917e55cd0001722704d1ea1e&profile_id=175',
          Type: 'video/mp4',
          Poster: getResizeImage('diving.jpg'),
        },
        Cta: { Title: 'Read more', Link: '#' },
      },
      {
        Title: 'Up close to Alaska whales 2',
        Text:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dolor morbi non arcu risus quis varius quam. Id consectetur purus ut faucibus pulvinar',
        Image: { Url: '@/assets/images/hike.jpg', Alt: 'Hikers' },
        Video: {
          Url:
            'https://player.vimeo.com/external/378567776.hd.mp4?s=a1da3193ace2f94a917e55cd0001722704d1ea1e&profile_id=175',
          Type: 'video/mp4',
          Poster: getResizeImage('diving.jpg'),
        },
        Cta: { Title: 'Read more', Link: '#' },
      },
    ],
  }),
})
export default class CarouselRichModule extends mixins(SwiperMixin) {
  readonly p!: CarouselRichModuleViewModel;
  wasInteracted = false;

  imageWidths: ImageWidths = {
    def: 463,
    sizes: {
      sm: 636,
      md: 992,
      lg: 793,
      xl: 993,
    },
  };

  activeIndex: number = 0;
  modalOpen: boolean = false;

  get swiperOption(): SwiperOptions {
    return {
      ...getDefaultSwiperOptions(this.uuid),
      on: {
        slideChange: () => this.onModuleInteraction('change'),
        touchStart: () => this.onModuleInteraction(),
      },
    };
  }

  onLockupClick(e) {
    if (e.target.tagName !== 'A') {
      this.$emit('moduleClicked', {position: this.activeIndex + 1});
      window.open(this.currentSlide.Cta.Link, '_self')
    }
  }

  onModuleInteraction(type?: string) {
    if (!this.wasInteracted) {
      SegmentElement.sendEvent('moduleInteraction', {
        module_type_id: this.$attrs['data-segmentid'],
        module_type_name: this.$attrs.type,
        interaction_type: 'carousel',
        position: type === 'change' ? this.swiper.activeIndex : this.swiper.activeIndex + 1,
      })
      this.wasInteracted = true;
    }
  }

  openModal() {
    this.modalOpen = true;
    this.$nextTick(() => {
      openModal(this.$modal, `video-player-${this.uuid}`);
    })
  }

  closeModal() {
    this.$nextTick(() => {
      closeModal(this.$modal, `video-player-${this.uuid}`);
    })
    this.modalOpen = false;
  }

  get currentSlide() {
    if (this.p.Slides.length === 0) return null;
    return this.p.Slides[this.activeIndex];
  }

  get containerAdjustClass() {
    return {
      'carousel-rich__container_extrasmall': this.p.Slides.every(s => {
        const text = s.Text ? stripHtml(s.Text) : ''
        return text.length <= 182
      }),
      'carousel-rich__container_small': this.p.Slides.every(s => {
        const text = s.Text ? stripHtml(s.Text) : ''
        return text.length > 182 && text.length < 320
      })
    }
  }

  mounted() {
    if (this.currentSlide) {
      this.swiper.on('transitionStart', () => {
        this.activeIndex = this.swiper.activeIndex;
      });
    }
  }
}
